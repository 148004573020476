import React, { FC } from 'react';
import { cx, css, keyframes } from 'emotion';
import { useStyles } from '@grafana/ui';
import { Branding } from '../Branding/Branding';
import { GrafanaTheme } from '@grafana/data';
// import { Footer } from '../Footer/Footer';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox: FC<InnerBoxProps> = ({ children, enterAnimation = true }) => {
  const loginStyles = useStyles(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export const LoginLayout: FC = ({ children }) => {
  const loginStyles = useStyles(getLoginStyles);
  return (
    <Branding.LoginBackground className={loginStyles.container}>
      <div className={loginStyles.header}>
        <img src="public/img/icons_riya/RBT-logo-w.png" />
      </div>
      <div className={loginStyles.container_left}>
        <div className={loginStyles.titleN}>
          <h1 className={loginStyles.titleN}>Welcome</h1>
          <h1 className={loginStyles.titleN}>To Riya Business Travel</h1>
          <p>Travel Management Hub for Corporate Travel</p>
        </div>
        <div className={loginStyles.listDiv}>
          <ul className={loginStyles.listStart}>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/flight-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/landtravel-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/mice-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/hotel-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/visa-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/mice-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/travel-insurance-icon.gif" />
              </span>
            </li>
            <li className={loginStyles.singleList}>
              <span></span>
            </li>
            <li className={loginStyles.singleList}>
              <span className={loginStyles.listSpan}>
                <img className={loginStyles.listImg} src="public/img/icons_riya/landtravel-icon.gif" />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className={loginStyles.rightStyle}>
        <div className={cx(loginStyles.loginContent)}>
          <div className={loginStyles.loginLogoWrapper}>
            <Branding.LoginLogo className={loginStyles.loginLogo} />
            <div className={loginStyles.titleWrapper}>
              <h1 className={loginStyles.mainTitle}>{Branding.LoginTitle}</h1>
              <h3 className={loginStyles.subTitle}>{Branding.GetLoginSubTitle()}</h3>
            </div>
          </div>
          <div className={loginStyles.loginOuterBox}>{children}</div>
        </div>
      </div>
      {/* <Footer /> */}
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme) => {
  // const bgColor = theme.isDark ? theme.palette.black : theme.palette.white;
  return {
    container: css`
      min-height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #181b4e !important;
      min-width: 100%;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    rightStyle: css`
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 40%;
      height: 100%;
      display: block;
      background: url(public/img/icons_riya/login-bg.png) no-repeat 0 0;
      background-size: cover;
    `,
    header: css`
      background: 'blue';
      width: '100%';
      position: absolute;
      padding: 10px;
      left: 0;
      top: 0;
    `,
    headerBg: css`
      background: url(public/img/icons_riya/RBT-logo-w.png) no-repeat 0 0;
      background-size: cover;
    `,
    container_left: css`
      padding: 10px;
      float: left;
      margin-left: 5%;
      margin-top: 3%;
      align-self: flex-start;
      // margin-bottom: 32px;
    `,
    titleN: css`
      color: white;
      text-align: center;
    `,
    listDiv: css`
      // padding: 10px;
    `,
    listStart: css`
      padding: 22px;
      margin-left: 2%;
      list-style: none;
      margin-right: 2%;
      max-width: 480px;
      text-align: center;
      background: url(public/img/icons_riya/footer-map-bg.png) no-repeat center;
      background-size: contain;
    `,
    singleList: css`
      width: 70px;
      height: 69px;
      display: inline-block;
    `,
    listSpan: css`
      border: 2px solid #2092ab;
      border-radius: 5px;
      padding: 29px 15px;
    `,
    listImg: css`
      width: 50px;
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      width: 100%;
      max-width: 100px;
      margin-bottom: 15px;
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing.lg};
    `,
    titleWrapper: css`
      text-align: center;
    `,
    mainTitle: css`
      font-size: 32px;
      color: black;
    `,
    subTitle: css`
      font-size: ${theme.typography.size.md};
      color: ${theme.colors.textSemiWeak};
    `,
    loginContent: css`
      max-width: 405px;
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: relative;
      justify-content: center;
      z-index: 1;
      right: 0;
      float: right;
      margin: 4%;
      @media (max-width: 520px) {
        right: 0 !important;
      }
      min-height: 320px;
      border-radius: 3px;
      padding: 20px 0;
    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: hidden;
      align-items: center;
      justify-content: center;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing.xl};
      @media (max-width: 320px) {
        padding: ${theme.spacing.lg};
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
  };
};
